<template>
    <v-layout v-if="ready" column justify-center>
        <div class="mb-40 mb-lg-60 text-center">
            <h3 v-if="!$route.query._board" class="tit tit--md">게시글 등록</h3>
            <h3 v-else class="tit tit--md">게시글 상세</h3>
        </div>

        <div class="form--primary">
            <form-row v-if="$route.params._board" tit="작성자">
                <v-text-field :value="(board.writer || {}).name" persistent-placeholder hide-details readonly disabled outlined></v-text-field>
            </form-row>
            <form-row v-if="$route.params._board" tit="실제작성일">
                <v-text-field v-model="$dayjs(board.createdAt).format('YYYY-MM-DD')" persistent-placeholder hide-details readonly disabled outlined></v-text-field>
            </form-row>
            <form-row v-if="$route.params._board" tit="최종수정일">
                <v-text-field v-model="board.updatedAt" placeholder="'YYYY-MM-DD' 형식을 지켜주세요" outlined hide-details></v-text-field>
            </form-row>
            <form-row tit="제목">
                <v-text-field v-model="board.subject" persistent-placeholder hide-details outlined placeholder="게시글 제목을  입력하세요."></v-text-field>
            </form-row>
            <form-row v-if="['volunteer', 'event', 'news', 'fair', 'newsletter'].includes(board.code)" tit="썸네일 이미지">
                <v-file-input v-model="board.thumb" persistent-placeholder class="mt-2 mx-3" show-size accept="image/*" outlined hide-details placeholder="썸네일 이미지를 첨부하세요."></v-file-input>
            </form-row>
            <form-row tit="첨부파일1">
                <v-file-input v-model="board.files[0]" show-size outlined hide-details placeholder="PDF 파일을 첨부하세요."/>
            </form-row>
            <form-row tit="첨부파일2">
                <v-file-input v-model="board.files[1]" show-size outlined hide-details placeholder="PDF 파일을 첨부하세요."/>
            </form-row>
            <form-row tit="첨부파일3">
                <v-file-input v-model="board.files[2]" show-size outlined hide-details placeholder="PDF 파일을 첨부하세요."/>
            </form-row>
            <form-row v-if="['volunteer', 'event'].includes(board.code)" tit="한줄설명">
                <v-text-field v-model="board.summary" persistent-placeholder outlined hide-details></v-text-field>
            </form-row>
            <form-row v-if="['event'].includes($route.query.code || board.code)" tit="진행여부">
                <v-switch v-model="board.isNotice" label="진행여부" />
            </form-row>
            <form-row v-if="['volunteer', 'event', 'news'].includes(board.code)" tit="이미지 미리보기">
                <v-img v-if="board.thumb" max-width="282" :src="createObjectURL(board.thumb)" contain class="border w-100" />
                <v-responsive v-else max-width="282" :aspect-ratio="1 / 1" class="grey-f5 border">
                    <v-layout column justify-center align-center class="w-100 h-100 text-center">
                        <p class="page-text grey-d--text">
                            권장 비율
                        </p>
                        <h4 class="tit tit--md grey-d--text">
                            1:1
                        </h4>
                    </v-layout>
                </v-responsive>
            </form-row>
            <form-row v-if="['news'].includes(board.code)" tit="파일">
                <v-file-input v-model="board.files[0]" show-size outlined hide-details />
            </form-row>
            <form-row v-if="!['news'].includes(board.code)" tit="내용">
                <naver-smarteditor v-model="board.content" id="content" rows="10"></naver-smarteditor>
            </form-row>
        </div>

        <div class="v-btn--group">
            <v-btn large @click="save" color="primary" class="min-w-140px min-w-lg-160px">저장</v-btn>
        </div>

        <!-- <v-responsive max-width="1024px" width="100%" height="100%" class="shop-board-view">
            <div class="mb-20 mb-lg-40">
                <h4 v-if="!$route.query._board" class="tit tit--sm line-height-1">게시글 등록</h4>
                <h4 v-else class="tit tit--sm line-height-1">게시글 상세</h4>
            </div>

            

            <v-card elevation="0" class="mb-16 mb-lg-32">
                <v-row v-if="$route.params._board">
                    <v-col cols="4" class="py-0">
                        <v-text-field :value="(board.writer || {}).name" label="작성자" persistent-placeholder hide-details readonly disabled></v-text-field>
                    </v-col>
                    <v-col cols="4" class="py-0">
                        <v-text-field v-model="$dayjs(board.createdAt).format('YYYY-MM-DD')" label="실제 작성일" persistent-placeholder hide-details readonly disabled></v-text-field>
                    </v-col>
                    <v-col cols="4" class="py-0">
                        <v-text-field v-model="board.updatedAt" label="최종수정일*('YYYY-MM-DD' 형식을 지켜주세요)*" ></v-text-field>
                    </v-col>
                </v-row>
                <v-row class="mt-3">
                    <v-col cols="12" class="py-0">
                        <p class="page-text grey-1--text line-height-1 mb-4 mb-lg-8">제목</p>
                        <v-text-field v-model="board.subject" persistent-placeholder hide-details outlined></v-text-field>
                    </v-col>
                    <v-col v-if="['volunteer', 'event'].includes(board.code)" cols="12" class="py-0">
                        <v-text-field v-model="board.summary" label="한줄설명" persistent-placeholder hide-details></v-text-field>
                    </v-col>
                </v-row>
                <v-row v-if="['event'].includes($route.query.code || board.code)" class="mt-6">
                    <v-col cols="6" class="py-0">
                        <v-switch v-model="board.isNotice" label="진행여부" />
                    </v-col>
                </v-row>
            </v-card>

            <v-card elevation="0" class="mt-4" v-if="['volunteer', 'event', 'news'].includes(board.code)">
                <v-card-title class="subtitle-1">썸네일</v-card-title>
                <v-card-text>
                    <v-row align="center">
                        <v-col cols="auto" class="py-0">
                            <v-img v-if="board.thumb" max-width="96" max-height="96" :src="createObjectURL(board.thumb)" contain></v-img>
                            <v-responsive v-else width="96" height="96" class="grey lighten-2">
                                <v-row align="center" class="fill-height" justify="center"><v-icon color="black">mdi-pencil</v-icon></v-row>
                            </v-responsive>
                        </v-col>
                        <v-col class="py-0">
                            <v-file-input v-model="board.thumb" label="썸네일 이미지" persistent-placeholder class="mt-2 mx-3" show-size accept="image/*"></v-file-input>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>

            <v-card elevation="0" v-if="['news'].includes(board.code)" class="mt-4">
                <v-card-title class="subtitle-1">파일</v-card-title>
                <v-card-text>
                    <v-file-input v-model="board.files[0]" show-size />
                </v-card-text>
            </v-card>

            <v-card elevation="0" class="mt-6" v-if="!['news'].includes(board.code)">
                <v-card-title class="subtitle-1">내용</v-card-title>
                <naver-smarteditor v-model="board.content" id="content" rows="10"></naver-smarteditor>
            </v-card>

            <div class="v-btn--group">
                <v-btn large color="primary" @click="save" class="min-w-140px min-w-lg-160px">저장</v-btn>
            </div>

        </v-responsive> -->
    </v-layout>
</template>

<script>
import api from "@/api";
import dayjs from "dayjs";

import VDatetimeField from "@/components/plugins/vuetify/v-datetime-field.vue";
import NaverSmarteditor from "@/components/plugins/naver/naver-smarteditor.vue";

import { mdiTagPlus } from "@mdi/js";

import FormRow from "@/components/client/sub/form-row.vue";

export default {
    components: {
        VDatetimeField,
        NaverSmarteditor,
        FormRow,
    },
    data() {
        return {
            mdiTagPlus,
            ready: false,

            ///////////////////////////////////////////////
            // 게시글 정보
            ///////////////////////////////////////////////
            board: {
                code: this.$route.query.code,

                subject: undefined,
                content: undefined,
                summary: undefined,
                period: undefined,
                updatedAt: undefined,

                sequence: 1,

                writer: {
                    name: undefined,
                    phone: undefined,
                    email: undefined,
                },

                thumb: undefined,
                files: [],
            },

        };
    },
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            try {
                if (this.$route.params._board) {
                    var { board } = await api.v1.boards.get({ _id: this.$route.params._board });
                    board.files = await Promise.all((board.files || []).map(async ({ url }) => await api.getResource(url, true)));
                    if (board.thumb) board.thumb = await api.getResource(board.thumb, true);
                    this.board = board;
                }

                if (!this.$route.params._board === undefined) {
                    var { summary } = await api.v1.boards.gets({
                        headers: { skip: 0, limit: 1 },
                        params: {
                            code: this.$route.query.code,
                        },
                    });
                    this.board.sequence = summary.totalCount + 1;
                }

                this.ready = true;
            } catch (error) {
                console.error(error);
                if (error.response) alert(error.response.data.message);
            }
        },
        async save() {
            try {
                let { _files = [], files = [], thumb, ...board } = this.board;
                console.log(this.board);
                ////////////////////////////////////////////////////////////////
                // 0. 리소스 삭제
                ////////////////////////////////////////////////////////////////
                if (_files.length > 0) await Promise.all(_files.map(async (_id) => await api.v1.boards.files.delete({ _board: board?._id, _id })));

                ////////////////////////////////////////////////////////////////
                // 1. 게시글 저장
                ////////////////////////////////////////////////////////////////
                [{ board }] = [board._id ? await api.v1.boards.put(board) : await api.v1.boards.post(board)];

                ////////////////////////////////////////////////////////////////
                // 2. 리소스 저장
                ////////////////////////////////////////////////////////////////
                if (thumb) await api.v1.boards.postThumb(board, thumb);
                if (files.length > 0) await Promise.all(files.map(async (file, index) => await api.v1.boards.files.post({ _board: board?._id, index }, file)));

                alert(this.board._id ? "수정되었습니다" : "저장되었습니다");

                this.$router.go(-1);
            } catch (error) {
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        },

        createObjectURL(file) {
            try {
                return URL.createObjectURL(file);
            } catch (error) {
                return;
            }
        },
    },
};
</script>
