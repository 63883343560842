<template>
    <div>
        <table class="board-view">
            <tr>
                <th>{{subject}}</th>
            </tr>
            <tr>
                <td>
                    <div v-for="file, idx in files" :key="idx">
                        첨부파일: <span @click="download(`${file.url}`, `${file.fileName}`)" class="cursor-pointer">{{file.fileName}}</span>
                    </div> 
                </td>
            </tr>
            <tr>
                <td class="content_box">
                    <div v-html="content"></div> 
                </td>
            </tr>
        </table>
        <div class="mt-40 mt-lg-60">
            <v-row justify="end" class="row--x-small">
                <v-col cols="auto">
                    <v-btn @click="$router.go(-1)" outlined color="grey-9">목록보기</v-btn>
                </v-col>
                <v-col cols="auto">
                    <v-btn v-if="!!write" @click="$router.push(`?mode=input`)" outlined color="grey-9">수정하기</v-btn>
                </v-col>
                <v-col cols="auto">
                    <v-btn v-if="!!write" @click="deleteBoard" outlined color="grey-9">삭제하기</v-btn>
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script>
import api from "@/api";
import download from "downloadjs";
export default {
    computed:{
    },
    props:{

    },
    data() {
        return {
            subject: null,
            content: null,
            write: false,
            board: null,
            files: null
        }
    },
    mounted() {
        this.init()
    },
    methods:{
        async init() {
            let { board } = await api.v1.boards.get({ _id: this.$route.params._board });
            this.board = board;
            this.subject = board.subject;
            this.content = board.content;
            this.files = board.files;

            if(this.$store.state.accessToken){
                let { user } = await api.v1.me.get();
                user.isApproved === true && board.writer._id === user._id ? this.write = true : this.write = false;
            }
        },
        async deleteBoard(){
            if(confirm("삭제하시겠습니까?")) {
                await api.v1.boards.delete(this.board);
                this.$router.go(-1);
            }
        },
        download(path, rename){
            api.getResource(path).then(file => download(file, rename));
        }
    }
}
</script>

<style>

</style>