<template>
    <client-page>

        <sub-visual-board :code="$route.query.code" :view="$route.params._board"/> 

        <section class="section">
            <v-container>

                <!-- <tab-fair v-if="$route.query.code === 'fair' && !$route.params._board && $route.query.mode !== 'input'" /> -->

                <board-list v-if="!$route.params._board && $route.query.mode !== 'input'"/>
                <board-write v-else-if="$route.query.mode === 'input'"/>
                <board-view v-else/>
                
            </v-container>
        </section>

    </client-page>
</template>

<script>
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import SubVisualBoard from "@/components/client/sub/sub-visual-borad.vue";
// import TabFair from "@/components/client/sub/tab-fair.vue";
import BoardView from "@/components/client/support/board-view.vue"
import BoardList from "@/components/client/support/board-list.vue"
import BoardWrite from "@/components/client/support/board-write.vue";

export default {
    components: {
        ClientPage,
        SubVisualBoard,
        // TabFair,
        BoardView,
        BoardList,
        BoardWrite
    },
    data() {
        return {
        }
    },
    mounted(){
        this.init()
    },
    methods: {
        async init(){
        }
    },
    watch:{
        code(){
        }
    }
}
</script>

<style>

</style>