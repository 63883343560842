<template>
    <sub-visual
    :subTabNone="view"
    sh="소통과 참여"
    :tabActive="tabActive"
    bg="/images/sub/visual/sv5.jpg">
    </sub-visual>
</template>

<script>
import SubVisual from "@/components/client/sub/sub-visual.vue";
export default {
    components: {
        SubVisual,
    },
    props: {
        code: String,
        view: Boolean,
    },
    computed: {
        tabActive() {
            if (this.code == 'notice') return "공지사항";
            if (this.code == 'report') return "보도자료";
            if (this.code == 'newsletter') return "재단소식";
            if (this.code == 'fair') return "행사 및 소식";
            if (this.code == 'reference') return "인재채용";
            // if (this.code == 'volunteer') return "자원봉사";
            // if (this.code == 'event') return "이벤트";
            else return;
        },
    },
};
</script>

<style lang="scss" scoped>
</style>