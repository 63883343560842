var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('sub-visual-board', {
    attrs: {
      "code": _vm.$route.query.code,
      "view": _vm.$route.params._board
    }
  }), _c('section', {
    staticClass: "section"
  }, [_c('v-container', [!_vm.$route.params._board && _vm.$route.query.mode !== 'input' ? _c('board-list') : _vm.$route.query.mode === 'input' ? _c('board-write') : _c('board-view')], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }