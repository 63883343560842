var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('sub-visual', {
    attrs: {
      "subTabNone": _vm.view,
      "sh": "소통과 참여",
      "tabActive": _vm.tabActive,
      "bg": "/images/sub/visual/sv5.jpg"
    }
  });

}
var staticRenderFns = []

export { render, staticRenderFns }